<template>
    <div>
        <slot
            v-if="false"
            name="error"
            v-bind:err="errData"
            v-bind:vm="vmData"
            v-bind:info="infoData"
        >
            <div
                class="fullscreen text-white text-center q-pa-md flex flex-center"
            >
                <div>
                    <div class="text-h2" style="opacity: 0.7">
                        Oops! Something went wrong.
                    </div>

                    <q-btn
                        class="q-mt-xl q-mr-sm text-weight-bold"
                        color="blue-1"
                        label="Contact Support"
                        to="#"
                    />
                    <q-btn
                        class="q-mt-xl text-weight-bold"
                        color="blue-1"
                        label="Try Again"
                        @click="reloadPage"
                    />
                </div>
            </div>
        </slot>
        <slot v-else></slot>
    </div>
</template>

<script>
import { defineComponent, onErrorCaptured, ref } from 'vue';

export default defineComponent({
    name: 'ErrorBoundary',
    props: {
        stopPropagation: Boolean,
    },
    setup(props) {
        const errData = ref(false);
        const vmData = ref(null);
        const infoData = ref(null);

        // err: error trace
        // vm: component in which error occurred
        // info: Vue specific error information such as lifecycle hooks, events etc.
        onErrorCaptured((err, vm, info) => {
            errData.value = err;
            vmData.value = vm;
            infoData.value = info;

            return !props.stopPropagation;
        });

        const reloadPage = () => {
            window.location.reload();
        };

        return { errData, vmData, infoData, reloadPage };
    },
});
</script>
