<template>
    <ErrorBoundary>
        <router-view />
    </ErrorBoundary>
</template>
<script lang="ts">
import ErrorBoundary from 'components/error/ErrorBoundary.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
    components: { ErrorBoundary },
});
</script>
